import { useRouter } from 'next/router'

import { useGlobalContext } from '@cms/hooks/useGlobalContext'
import { pushRoute } from '@knauf-group/ct-designs/utils/utils'
import { linkToReferenceProps } from '@knauf-group/ct-shared-nextjs/web/utils/utils'

import Ribbon from './Ribbon'
import type { CMSRibbonWrapperProps, RibbonProps } from './Ribbon.types'

const RibbonWrapper: React.FC<CMSRibbonWrapperProps> = ({ fields }) => {
  const router = useRouter()
  const { siteStructure } = useGlobalContext()
  const ribbonProps: RibbonProps = {
    fields,
    dataCy: 'ribbon',
    handleClick: () => pushRoute(ribbonProps.primaryRef, router),
    primaryRef: linkToReferenceProps(fields?.primaryReference, siteStructure),
  }

  return <Ribbon {...ribbonProps} />
}

export default RibbonWrapper
